@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
  font-family:"Space Grotesk";
  src: url('fonts/SpaceGrotesk-Variable.ttf') format('truetype')
}

@font-face{
  font-family:"Moderat";
  src: url('fonts/Moderat-Black.ttf') format('truetype')
}

:root {
  --orange50: #FFEFE8;
  --orange60070: #e4561890;
  --orange600: #e45618;
  --orange700: #b24312;

  --inputGray: #eeeeee;

  --black5: #E1DADA;
  --black10: #6b6b6b;
  --black400: #515151;
  --black500: #252525;
  --black600: #222222;
  --black700: #1a1a1a;
  --black800: #1D2939;

  --horizontalMargin: 2.5rem;

  --borderRadius: 3.125rem;

  --marginBtwSection: 2.5rem;
  --marginBtwElements: 1.25rem;

  --blueLink: #007ef2;
  --goldColor: #c9a464;

  --grey10: #fcfcfd;
  --grey50: #f9fafb;
  --grey100: #f2f4f7;
  --grey400: #98a2b3;
  --grey300: #d0d5dd;
  --grey500: #667085;
  --grey600: #475467;
  --grey700: #344054;
  --grey900: #101828;
  --error500: #d72f2f;

  --primaryGreen500: #1db954;
  --fontSize: 0.75rem;
}

/* input:focus{
  outline: 1px solid var( --orange700)
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::selection {
  background-color: black;
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 2rem;
  height: 1.25rem;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(----primaryGreen500);
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
